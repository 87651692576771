import { LandingPage as LandingPageForm } from '@bgea-js/form-templates';
import donationHeaderHymnsfortheSoul from "../../../../src/content/classic-hymns/donationHeader";
import { NewNames as NewNamesForm } from "@bgea-js/form-templates";
import "../../../../src/content/classic-hymns/classic-hymns.scss";
import * as React from 'react';
export default {
  LandingPageForm,
  donationHeaderHymnsfortheSoul,
  NewNamesForm,
  React
};